<template>
<!--     <div class="container is-fluid is-dark">
        <div class="container has-text-white-ter pt-5 pb-5">
            <h2 class="title is-3 has-text-grey-lighter"><span class="has-text-primary">Con</span>tact</h2>
            <hr class="is-hidden-mobile has-background-grey">
            <div class="columns">
                <ul class="column">
                    <li>{{contactInfo.amsterdam.street}}</li>
                    <li>{{contactInfo.amsterdam.zip}} {{contactInfo.amsterdam.city}}</li>
                    <li><a :href="`tel:${contactInfo.amsterdam.callableNumber}`">{{contactInfo.amsterdam.phone}}</a></li>
                    <li><a :href="`mailto:${contactInfo.amsterdam.email}`">{{contactInfo.amsterdam.email}}</a></li>
                </ul>
                <ul class="column">
                    <li>{{contactInfo.goorn.street}}</li>
                    <li>{{contactInfo.goorn.zip}} {{contactInfo.goorn.city}}</li>
                    <li><a :href="`tel:${contactInfo.goorn.callableNumber}`">{{contactInfo.goorn.phone}}</a></li>
                    <li><a :href="`mailto:${contactInfo.goorn.email}`">{{contactInfo.goorn.email}}</a></li>
                </ul>
            </div>
        </div>
    </div> -->
    <div></div>
</template>
<script>
export default {
    name: "FooterComponent"
};
</script>

<style type="text/css" scoped>

    @media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
        .container.is-fluid {
            padding-left: 0 ;
            padding-right: 0;
        }
    }

    .is-dark{
        background-color: #444;
    }
</style>