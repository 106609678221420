<template>
    <NotificationCentrum/>
    <div v-if="!['login'].includes($route.name) && profile != null" id="content-container" class="min-height margin-navbar">
      <NavbarComponent/>
      <router-view></router-view>
    </div>
    <router-view v-else-if="['login'].includes($route.name)"></router-view>
    <FooterComponent/>
</template>
<script>
import NavbarComponent from './components/core/NavbarComponent'
import FooterComponent from './components/core/FooterComponent'
import NotificationCentrum from './components/core/NotificationCentrum'
import { mapGetters } from "vuex";
import Auth from './modules/auth/Auth.js'

export default {
  name: 'App',
  components: {
    NavbarComponent,
    FooterComponent,
    NotificationCentrum
  },
  async mounted() {
    if (!Auth.hasLoggedIn()) { 
      this.$router.push('/')
    } else {
      // Initial app profile loading when refreshing page
      await this.$store.dispatch('getProfile')
      this.$router.push('/dashboard/' + this.$store.state.profile.profile.settlements[0].id);
    }
  },
  computed: {
      ...mapGetters(["profile"]),
  },
}
</script>


<style type="text/css" scoped>

    #content-container {
      display: flex;
      flex-direction: row;
      min-height: calc(100vh - 86px);
    }

    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        #content-container {
          min-height: calc(100vh - 86px);
        }
    }

    .margin-navbar{
      margin-top: 86px;
    }
</style>

<style type="text/css">

    :root {
      --primary: #edbe15;
    }
  
    #app .has-text-primary {
        color: var(--primary) !important
    }

    #app .button.is-primary {
        box-shadow: none !important;
        background-color: var(--primary) !important;
        color: rgba(0, 0, 0, 0.7) !important;
    }

    #app .select:not(.is-multiple):not(.is-loading)::after {
      border-color: var(--primary) !important;
  }

    .input:focus, 
    .textarea:focus, 
    .select select:focus, 
    .is-focused.input, 
    .is-focused.textarea, 
    .select select.is-focused, 
    .input:active, 
    .textarea:active, 
    .select select:active, 
    .is-active.input, 
    .is-active.textarea, 
    .select select.is-active {
        border-color: var(--primary) !important;
        box-shadow: none !important;
    }

    a {
        color:  var(--primary) !important;
    }

    div.apexcharts-toolbar {
      display: none !important;
    }

    g.apexcharts-grid {
      display: none !important;
    }

    #app input[type="date"] {
      padding:  8px;
    }

    .box.underlined {
        border-bottom: solid 2px var(--primary);
    }

    /*g.apexcharts-yaxis-texts-g {
      display: none !important;
    }*/

    #app .button.is-primary.floating-action {
      position: fixed;
      bottom: 20px;
      right: 20px;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      box-shadow: 1px 4px 3px #999 !important;
    }

    #app .button.is-primary.floating-action:hover {
      box-shadow: 1px 6px 4px #999 !important;
    }

    @media screen and (min-width: 1024px) {
      #app .button.is-primary.floating-action {
        left: calc(50% + (960px / 2));
      }
    }

</style>
