<template>
    <div id="login-section" class="container has-text-centered p-4 pt-4 pb-6">
        <div class="box" id="card-container">
            <h2 class="title is-3 has-text-primary">Inloggen</h2>
            <br>
            <form ref="form" class="is-mobile" action="#" method="POST">
                <input v-on:input="inputChange" class="input" type="text" name="username" placeholder="johndoe@example.com" v-model="username"><br>
                <input v-on:input="inputChange" class="input" type="password" name="password" placeholder="*********" v-model="password"><br>
                <p v-if="error" class="has-text-error"><br>{{error}}</p>
                <br>
                <div id="button-section">
                    <input class="button is-primary" type="submit" value="Inloggen" v-on:click="submit">
                    <!-- <router-link to="/forgot-password" class="">Forgot password</router-link> -->
                </div>
            </form>
        </div>
    </div>
</template>
<script>

import DI from '../../modules/di/DI.js'
import Auth from '../../modules/auth/Auth.js'
import { mapGetters } from "vuex";
  
export default {
    name: "LoginComponent",
    data: () => {
        return {
            error: '',
            username:'',
            password: ''
        }
    },
    async created() {
        if (Auth.hasLoggedIn() && !this.$store.state.profile.profile) { 
            // Initial app profile loading when refreshing page
            await this.$store.dispatch('getProfile')
            this.$router.push('/dashboard/' + this.$store.state.profile.profile.settlements[0].id);
        } else if (Auth.hasLoggedIn() && this.$store.state.profile.profile) {
            this.$router.push('/dashboard/' + this.$store.state.profile.profile.settlements[0].id);
        }
    },
    computed: {
        ...mapGetters(["profile"]),
    },
    methods: {
        inputChange(e) {
            e.preventDefault()
            this.error = ''
        },
        async submit(e) {
            e.preventDefault()
            try {
                var response = await DI.api.login(this.username, this.password)
                Auth.setAccessToken(response.data.access_token)
                Auth.setRefreshToken(response.data.refresh_token)
                await this.$store.dispatch("getProfile");
                // Re-direct:
                this.$router.push('/dashboard/' + this.$store.state.profile.profile.settlements[0].id);
            } catch(e) {
                this.error = e 
            }
        }
    }
};
</script>

<style type="text/css" scoped>

    .has-text-error {
        color: #ee0000;
    }

    #login-section {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }

    #button-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    #card-container {
        width: 75%;
        padding: 40px;
    }

    form {

        width: 100%;
        margin:  0 auto;
    }

    @media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
        #card-container {
            width: 80%;
        }
    }

</style>