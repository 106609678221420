<template>
    <div class="container">
        <!-- Breadcrumbs -->
        <ul class="m-4 horizontal breadcrumbs">
            <li><router-link :to="{ name: 'dashboard', params: { id: settlement ? settlement.id : '0' }}"><span class="is-size-6">Dashboard</span></router-link></li>
            <li><router-link :to="{ name: 'additions', params: { id: settlement ? settlement.id : '0' }}"><span class="is-size-6">Extra's</span></router-link></li>
            <li>
                <router-link :to="{ name: 'create-addition', params: { 
                    settlementId: settlement ? settlement.id : '0'
                }}">
                    <span class="is-size-6">Toevoeging</span>
                </router-link>
            </li>
        </ul>

        <div class="box shadow m-4">
            <h1 class="is-size-4 has-text-primary">Extra's {{ settlement ? 'van ' + settlement.name : '' }}</h1>
            <h2 class="is-size-6">Creëer een nieuwe toevoeging</h2>
        </div>

        <div class="product-details">
            <div class="box shadow m-4">
                <h1 class="has-text-primary is-size-5">Details</h1>
                <hr>
                <form>
                    <label for="name" class="has-text-primary is-size-6 pl-4">Toevoeging</label>
                    <input v-model="item.name" type="text" id="name" name="name" placeholder="Omschrijving" class="input is-rounded">
                    <label for="price" class="has-text-primary is-size-6 pl-4">Prijs (incl. BTW)</label>
                    <input v-model="item.price" type="text" id="price" name="price" placeholder="Prijs" class="input is-rounded" @input="fixPriceFormat">
                    <label for="btwLevelId" class="has-text-primary is-size-6 pl-4">BTW type</label><br>
                    <div class="select is-rounded">
                        <select id="btwLevelId" name="btwLevelId" v-model="item.taxLevelId">
                            <option v-for="taxLevel in taxLevels" :value="taxLevel.id" :key="taxLevel.id">
                                {{ taxLevel.displayName + " (" + taxLevel.percentage + "%)" }}
                            </option>
                        </select>
                    </div>
                </form>
            </div>
        </div>

        <button class="button is-primary floating-action" @click="save">
            <span class="icon">
                <i class="fas fa-save"></i>
            </span>
        </button>
    </div>
</template>
<script>

import { mapGetters } from "vuex";
import DI from '../../modules/di/DI.js';
import Formatter from '../../plugins/Formatter.js'
export default {
    name: "ProductCreatePage",
    components: {
    },
    data () {
        return {
            settlement: null,
            item: {
                name: "",
                price: null,
                taxLevelId: null,
                settlementId: null
            },
            taxLevels: [],
            Formatter
        }
    },
    computed: {
        ...mapGetters(["profile"]),
    },
    // watch: {
    //     '$route.params.settlementId' : function (settlementId) {
    //         if (settlementId != null) {
    //             this.load()
    //         } else {
    //             console.log('nani, don\'t load')
    //             console.log(settlementId)
    //         }
    //     }
    // },
    async created () {
        this.load()
    },
    methods: {
        async load() {
            this.settlement = this.getSettlement()
            if (this.settlement == null) {
                this.$router.push('/not-found')
            } else {

                var taxLevelsResponse = await DI.api.getTaxLevels();
                this.taxLevels = taxLevelsResponse.data

                // Assign a default tax level:
                this.item.btwLevelId = this.taxLevels[0].id;
            }
        },
        getSettlement () {
            var settlementId = this.$route.params.settlementId
            var settlements = this.$store.state.profile.profile.settlements
            for(var i = 0; i < settlements.length; i++) {
                let settlement = settlements[i]
                if(settlement.id == settlementId) {
                    return settlement
                }
            }
            return null
        },
        fixPriceFormat () {
            return this.item.price = ("" + this.item.price).replace(",", ".")
        },
        async save () {
            
            var itemDTO = {
                name: this.item.name,
                price: parseFloat(this.item.price),
                btwLevelId: this.item.taxLevelId,
                settlementId: this.$route.params.settlementId
            }

            try {
                await DI.api.createAddition(itemDTO)
                // Re-direct to the overview:
                this.$router.push('/settlement/' + this.settlement.id + '/additions');
                await this.$store.dispatch('addNotification', {
                    message: 'Uw toevoeging "' + this.item.name + '" is aangemaakt.'
                });
            } catch (e) {
                await this.$store.dispatch('addNotification', {
                    message: "Er ging iets mis bij het opslaan. Controleer uw invoer en probeer het opnieuw."
                })
            }
        }
    }
};
</script>

<style type="text/css" scoped>

    ul.horizontal {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
    ul.horizontal li {
        float: left;
        font-style: italic;
    }
    ul.breadcrumbs li:not(:first-child):before {
        content:"\A";
        width:5px;
        height:5px;
        border-radius:50%;
        display:inline-block;
        margin:3px 10px;
        background: var(--primary); /*var(--primary);*/
    }
    ul.breadcrumbs li:last-child:before {
        background: #999;
    }
    ul.breadcrumbs li > a.router-link-exact-active {
        color: #999 !important;
    }

    .product-details {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    @media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
        .product-details {
            display: grid;
            grid-template-columns: auto;
        }
    }
</style>