<template>
    <nav v-if="!['login'].includes($route.name)" class="navbar is-fixed-top shadow" role="navigation" aria-label="main navigation">
        <div class="container"> 
            <div class="navbar-brand is-align-items-center">
                <router-link to="/" class="navbar-item">
                    <img class="logo" src="@/assets/logo.png">
                    <h1 class="has-text-primary is-size-4">{{ profile ? profile.name : '' }}</h1>
                </router-link>
                
                <a href="" class="button is-primary ml-a mr-3 is-hidden-desktop" id="menu-mobile" @click="toggleMenu">
                    <span v-if="!isOpen" class="icon-text is-large">
                        <span class="icon">
                            <i class="fas fa-bars"></i>
                        </span>
                    </span>
                    <span v-else class="icon-text is-large">
                        <span class="icon">
                            <i class="fas fa-times"></i>
                        </span>
                    </span>
                </a>
            </div>
            <div id="navbar" class="navbar-menu">
                <div class="navbar-end">
                    <div class="navbar-item is-hidden-touch">
                        <div class="buttons">
                            <a href="" class="button is-primary" id="menu" @click="toggleMenu">
                                <span v-if="!isOpen" class="icon-text is-large">
                                    <span class="icon">
                                        <i class="fas fa-bars"></i>
                                    </span>
                                </span>
                                <span v-else class="icon-text is-large">
                                    <span class="icon">
                                        <i class="fas fa-times"></i>
                                    </span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <div v-if="isOpen" class="full-menu" @click="closeMenu">
        <div class="container box flow-auto">
            <div class="columns">

                <div class="column">
                    <div v-for="settlement in profile.settlements" v-bind:key="settlement.id">
                        <h1 class="is-size-5">{{ settlement.name }}</h1>
                        <ul class="mb-4">
                            <li class="pt-1 pb-1"><router-link :to="{ name: 'dashboard', params: { id: settlement.id}}">Dashboard</router-link></li>
                            <li class="pt-1 pb-1"><router-link :to="{ name: 'revenue', params: { id: settlement.id}}">Omzet</router-link></li>
                            <!-- <li class="pt-1 pb-1"><router-link :to="{ name: 'dashboard', params: { id: settlement.id}}">Categorieën</router-link></li> -->
                            <li class="pt-1 pb-1"><router-link :to="{ name: 'products', params: { settlementId: settlement.id}}">Producten</router-link></li>
                            <!-- <li class="pt-1 pb-1"><router-link :to="{ name: 'dashboard', params: { id: settlement.id}}">Ingrediënten</router-link></li> -->
                            <li class="pt-1 pb-1"><router-link :to="{ name: 'additions', params: { settlementId: settlement.id}}">Extra's</router-link></li>
                        </ul>
                    </div>
                </div>

                <div class="column">
                    <p class="is-size-4">Overige</p>
                    <a class="is-size-6" href="" @click="logout">Uitloggen</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Auth from '../../modules/auth/Auth.js'
import { mapGetters } from "vuex";
export default {
    name: "NavbarComponent",
    data: () => {
        return {
            isOpen: false
        }
    },
    computed: {
        ...mapGetters(["profile"]),
    },
    async created() {
        if( !['login'].includes(this.$route.name)) {
            await this.$store.dispatch("getProfile");
        }
    },
    methods: {
        closeMenu: function() {
            this.isOpen = false
        },
        toggleMenu: function(e) {
            e.preventDefault()
            this.isOpen = !this.isOpen
        },
        logout: function(e) {
            e.preventDefault();
            Auth.logout()
            this.$router.push('/')
        }
    }
}
</script>

<style type="text/css" scoped>

    .full-menu {
        position: fixed;
        width: 100vw;
        height: calc(100vh - 86px);
        background: rgba(0,0,0,0.1);
        backdrop-filter: blur(5px);
        z-index: 29;
        padding: 20px;
    }

    .logo {
        width: auto;
        height: 70px;
        max-height: unset;
    }

    .ml-a {
        margin-left: auto;
    }

    .shadow {
        box-shadow: -3px 8px 17px -2px rgb(0 0 0 / 20%);
    }

    .flow-auto {
        overflow: auto;
    } 

     @media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
        .full-menu .container.box {
            height: 100%;
        }
    }
</style>