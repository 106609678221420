<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" @click="close">
            <div class="modal" role="dialog">
                <div class="card">
                    <button type="button" class="btn-close" @click="close" aria-label="Close modal">x</button>
                    <slot name="content"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
  export default {
    name: 'ModalComponent',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style type="text/css">
    .modal-buttons {
        float: right;
    }
</style>

<style type="text/css" scoped>

  .modal > .card {
    padding: 2rem;
    max-width: 300px;
  }
  .modal-backdrop {
    z-index: 30;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: var(--dark-grey);
    background: transparent;
  }

  .modal-fade-enter {
    opacity: 0;
  }

  .modal-fade-enter-active {
    transition: opacity .5s ease;
  }
</style>