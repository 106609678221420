import DI from '../../../modules/di/DI.js';

const state = {
    additions: [],
    addition: null
}

const mutations = {
    async UPDATE_ADDITIONS (state, payload) {
        return new Promise(function(resolve) {
            // Sort them:
            payload.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            state.additions = payload
            resolve()
        })
    },
    async UPDATE_ADDITION (state, payload) {
        return new Promise(function(resolve) {
            state.addition = payload
            resolve()
        })
    }
}

const actions = {
    async getAdditions ({ commit }, payload) {
        var response = await DI.api.getAdditions(payload.settlementId)
        await commit('UPDATE_ADDITIONS', response.data)
    },
    async getAdditionById ({ commit }, payload) {
        var response = await DI.api.getAdditionById(payload.id)
        await commit('UPDATE_ADDITION', response.data)
    }
}

const getters = {
    additions: state => state.additions,
    addition: state => state.addition
}

const additionsModule = {
    state,
    mutations,
    actions,
    getters
}

export default additionsModule;