<template>
    <div class="container">
        <!-- Breadcrumbs -->
        <ul class="m-4 horizontal breadcrumbs">
            <li><router-link :to="{ name: 'dashboard', params: { id: settlement ? settlement.id : '0' }}"><span class="is-size-6">Dashboard</span></router-link></li>
            <li><router-link :to="{ name: 'additions', params: { id: settlement ? settlement.id : '0' }}" class="router-link-active router-link-exact-active"><span class="is-size-6">Extra's</span></router-link></li>
        </ul>

        <div class="box shadow m-4">
            <h1 class="is-size-4 has-text-primary">Extra's {{ settlement ? 'van ' + settlement.name : '' }}</h1>
            <h2 class="is-size-6">Beheer uw toevoegingen</h2>
        </div>

        <!-- Table for desktop -->
        <div v-if="items.length > 0">

            <div class="box shadow m-4 mt-5 overview">
                <div class="has-text-primary overview-row ">Toevoeging</div>
                <div class="has-text-primary overview-row has-text-right">Prijs</div>
                <template v-for="row in items" :key="row">
                    <div class="overview-row">
                        <router-link :to="{ name: 'addition-details', params: { settlementId: settlement ? settlement.id : '0', itemId: row.id }}">
                            <span class="is-size-6">{{ row.name }}</span>
                        </router-link>
                    </div>
                    <div class="overview-row has-text-right">
                        € {{ formatter.format(row.price, 2, ',', '.') }}
                        
                        <span class="icon pointer" v-on:click="openDeletePopUp(row)">
                            <i class="fas fa-trash"></i>
                        </span>
                    </div>
                </template>
            </div>
        </div>

        <!-- Delete addition modal -->
        <ModalComponent v-show="selectedItem != null" @close="closeDeletePopup">
            <template v-slot:content>
                <h1>
                    Weet je zeker dat je "<span class="has-text-primary">{{ selectedItem ? selectedItem.name : '' }}</span>" wilt verwijderen? 
                    Dit verwijderd het product uit <span class="has-text-primary">ALLE</span> actieve categorieën.
                </h1>
                <div class="modal-buttons">
                    <button class="button is-size-6 is-primary has-text-weight-bold" v-on:click="deleteItem">Ja</button>
                    <button class="button is-size-6 has-text-weight-bold has-text-primary">Nee</button>
                </div>
            </template>
        </ModalComponent>

    </div>
    <button class="button is-primary floating-action" @click="addItem">+</button>
</template>
<script>

// import DI from '../../modules/di/DI.js'
import { mapGetters } from "vuex";
import Formatter from '../../plugins/Formatter.js';
import ModalComponent from '../core/ModalComponent.vue';
import DI from '../../modules/di/DI.js';

export default {
    name: "AdditionsPage",
    components: {
        ModalComponent
    },
    data () {
        return {
            formatter: Formatter,
            settlement: null,
            items: [],
            selectedItem: null
        }
    },
    computed: {
        ...mapGetters(["profile"]),
    },
    watch: {
        '$route.params.settlementId' : function (id) {
            if (id) {
                this.load()
            }
        }
    },
    async created () {
        this.load()
    },
    methods: {
        closeDeletePopup () {
            this.selectedItem = null;
        },
        openDeletePopUp (item) {
            this.selectedItem = item;
        },
        async deleteItem () {
            await DI.api.deleteAddition(this.selectedItem.id);
            this.selectedItem = null;
            await this.load()
        },
        async load() {
            this.settlement = this.getSettlement()
            if (this.settlement == null) {
                this.$router.push('/not-found')
            } else {
                await this.$store.dispatch(
                    'getAdditions', {
                        settlementId: this.settlement.id
                    }
                )
                this.items = this.$store.state.addition.additions
            }
        },
        getSettlement () {
            var settlementId = this.$route.params.settlementId
            var settlements = this.$store.state.profile.profile.settlements
            for(var i = 0; i < settlements.length; i++) {
                let settlement = settlements[i]
                if(settlement.id == settlementId) {
                    return settlement
                }
            }
            return null
        },
        addItem () {
            this.$router.push('/settlement/' + this.settlement.id + '/additions/create');
        }
    }
};
</script>

<style type="text/css" scoped>

    .overview {
        display: grid;
        grid-template-columns: auto auto;
    }

    .overview-row a {
        color: var(--dark-grey) !important;
    }

    .overview-row a:hover, .overview-row a:active {
        color: var(--primary) !important;
    }

    .grid-3 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0 10px;
    }

    .overview-row {
        padding: 10px;
        text-align: left;
    }

    ul.horizontal {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
    ul.horizontal li {
        float: left;
        font-style: italic;
    }
    ul.breadcrumbs li:not(:first-child):before {
        content:"\A";
        width:5px;
        height:5px;
        border-radius:50%;
        display:inline-block;
        margin:3px 10px;
        background: var(--primary); /*var(--primary);*/
    }
    ul.breadcrumbs li:last-child:before {
        background: #999;
    }
    ul.breadcrumbs li > a.router-link-exact-active {
        color: #999 !important;
    }

    .grid-3 > .box {
        margin-bottom: 0;
    }

    .pointer {
        cursor: pointer;
    }


    @media only screen and (min-device-width : 0px) and (max-device-width : 480px) {

    }

/*    .box.underlined {
        border-bottom: solid 2px var(--primary);
    }*/

</style>